.marker-label-pos{
  /* font-family: var(--font-semibold) !important; */
  color:#f26520 !important;
  padding-top: 5% !important;
  margin-top: 50% !important;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff !important;

}

.beacon {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 3em;
  width: 3em;
  border-radius: 50%;
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 1;
  /* z-index: -1; */
  text-align: center;
}

.beacon:before,
.beacon:after {
  position: absolute;
  content: "";
  height: 3em;
  width: 3em;
  left: 0;
  top: 0;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 0 0 0 3px #ed1f34;
  -webkit-animation: active 2s infinite linear;
  -moz-animation: active 2s infinite linear;
  animation: active 2s infinite linear;
}

.beacon:after {
  -moz-animation-delay: 1s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

@-webkit-keyframes active {
  0% {
    -webkit-transform: scale(0.1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(2.5);
    opacity: 0;
  }
}

@keyframes active {
  0% {
    transform: scale(0.1);
    transform: box-shadow(0px 0px 0px 1px #f26520);
    opacity: 1;
  }

  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

.pin {
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #f26520;
}

.pin-text {
  color: #f26520;
  font-size: 1.5em;
  top: 50%;
  left: 50%;
  height: 3em;
  width: 3em;
  border-radius: 50%;
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  font-weight: bold;
  padding: 10px;
  position: absolute;
  z-index: 100;
}

.customized-map button {
  background-color: #fff !important;
}
