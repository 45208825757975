body {
  /* fallback for old browsers */
  background: #ffffff;
  font-family: "Roboto", helvetica, arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  /* Chrome 10-25, Safari 5.1-6 */
  /* background: -webkit-linear-gradient(to right, #076535, #e0dddb); */

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  /* background: linear-gradient(to right, #076535, #cecbcb) */
}

/* Sidebar */
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 58px 0 0;
  /* Height of navbar */
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
  width: 240px;
  /* z-index: 600; */
}

/* Table */
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700,300,100);

div.table-title {
  display: block;
  margin: auto;
  max-width: 600px;
  padding: 5px;
  width: 100%;
}

.table-title {
  color: #fafafa;
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-family: "Roboto", helvetica, arial, sans-serif;
  text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
}

/*** Table Styles **/
th {
  background-color: #00b464 !important;
  color: #ffffff;
  border-bottom: 4px solid #9ea7af;
  border-right: 1px solid #00b464;
  font-size: 1rem;
  font-weight: 500;
  padding: 1rem;
  text-align: center;
  vertical-align: middle;
}

/* hover on th */
th:hover {
  background: #c1c3d1 !important;
  color: #00b464;
  cursor: pointer;
}

th:first-child {
  border-top-left-radius: 3px;
}

th:last-child {
  border-top-right-radius: 3px;
  border-right: none;
}

tr {
  border-top: 1px solid #c1c3d1;
  border-bottom: 1px solid #c1c3d1;
  color: #666b85;
  font-weight: normal;
  text-shadow: 0 1px 1px rgba(256, 256, 256, 0.1);
}

tr:hover td {
  background: #00b464;
  color: #ffffff;
  border-top: 1px solid #00b464;
}

tr:first-child {
  border-top: none;
}

tr:last-child {
  border-bottom: none;
}

tr:nth-child(odd) td {
  background: #ebebeb;
}

tr:nth-child(odd):hover td {
  background: #87bd40;
}

tr:last-child td:first-child {
  border-bottom-left-radius: 3px;
}

tr:last-child td:last-child {
  border-bottom-right-radius: 3px;
}

td {
  background: #ffffff;
  vertical-align: middle;
  font-weight: 300;
  text-shadow: #076535;
  border-right: 1px solid #c1c3d1;
  word-wrap: break-word;
}

td:last-child {
  border-right: 0px;
}

th.text-left {
  text-align: left;
}

th.text-center {
  text-align: center;
}

th.text-right {
  text-align: right;
}

td.text-left {
  text-align: left;
}

td.text-center {
  text-align: center;
}

td.text-right {
  text-align: right;
}

button {
  background-color: #00b464 !important;
  /* Green */
  border: none !important;
  color: white;
  padding: 8px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 0 !important;
}

.active {
  background-color: #00b464 !important;
  color: #ffffff !important;
}

a {
  color: #666b85 !important;
}

.active > .page-link {
  background-color: #00b464 !important;
  border-color: #00b464 !important;
  color: #ebebeb !important;
}

.title {
  color: #00b464 !important;
}

.active {
  background-color: #00b464 !important;
  color: #ebebeb !important;
}
