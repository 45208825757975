.tracker-btn {
  width: 80%;
  background: white !important;
  color: #87bd40;
  border: 2px solid #87bd40 !important;
  border-radius: 20px !important;
  height: 11em;
  font-size: large;
}
.tracker-btn:hover {
  background: #87bd40 !important;
  color: whitesmoke;
}
